import React, { Component } from 'react';
import { IconButton, Grid, Tooltip } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  getFromLocalStorage,
  saveToLocalStorage
} from '../Utilities/saveStateHelper';
import { isMobileDevice } from '../common/utilities';

export default class ChartsWrapper extends Component {
  render() {
    const showCharts = getFromLocalStorage('showCharts', !isMobileDevice());
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ margin: '15 0' }}
      >
        <Grid container justifyContent="flex-start" alignItems="center">
          <Tooltip
            title={`Click to ${showCharts ? 'hide charts' : 'show charts'}`}
            placement="bottom"
          >
            <IconButton
              key="hide"
              aria-label={showCharts ? 'Hide Charts' : 'Show Charts'}
              color="inherit"
              onClick={() => {
                saveToLocalStorage('showCharts', !showCharts);
                this.forceUpdate();
              }}
              size="large"
            >
              {showCharts ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </Tooltip>
        </Grid>
        {showCharts && (
          <Grid item xs={12} sm={12} data-testid={this.props.testId}>
            {this.props.children}
          </Grid>
        )}
      </Grid>
    );
  }
}
