import { logError } from '../../../Utilities/logError';

export const getTableData = async (params, request, callBack) => {
  request(params)
    .then(({ data: rows }) =>
      callBack({
        rows: rows && typeof rows === 'object' ? rows : [],
        loading: false
      })
    )
    .catch((error) => {
      logError(error);
      callBack({ loading: false, loadingError: true });
    });
};

export const getTableDataWithTotalRows = async (params, request, callBack) => {
  request(params).then(({ data }) =>
    callBack({
      rows: data.data && typeof data === 'object' ? data.data : [],
      loading: false,
      totalCount: data.total_rows
    })
  );
};

export const getTableDataWithTotalRowsAndMostUpdated = async (
  params,
  request,
  callBack
) => {
  request(params).then(({ data }) =>
    callBack({
      rows: data.rows && typeof data === 'object' ? data.rows : [],
      loading: false,
      totalCount: data.totals,
      lastUpdated: data.most_recent
    })
  );
};

export const getTableDataWithMostUpdated = async (
  params,
  request,
  callBack,
  failCallBack = () => {}
) => {
  request(params)
    .then(({ data: { rows, most_recent } }) =>
      callBack({
        rows: rows && typeof rows === 'object' ? rows : [],
        loading: false,
        lastUpdated: most_recent
      })
    )
    .catch(() => failCallBack());
};

export const getTableDataWithClickTotalsAndMostUpdated = async (
  params,
  request,
  callBack,
  failCallBack = () => {}
) => {
  request(params)
    .then(({ data: { rows, most_recent, clickTotals } }) =>
      callBack({
        rows: rows && typeof rows === 'object' ? rows : [],
        clickTotals:
          clickTotals && typeof clickTotals === 'object' ? clickTotals : [],
        loading: false,
        lastUpdated: most_recent
      })
    )
    .catch(() => failCallBack());
};
