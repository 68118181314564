/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _noop from 'lodash/noop';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, Dialog as ModalDialog } from '@mui/material';
import { keyframes } from 'tss-react';
import { withStyles } from 'tss-react/mui';
import { colors } from '../../Utilities/LenoxColors';
import ConfirmDialog from './ConfirmDialog';
import SuccessDialog from './SuccessDialog';
import ErrorDialog from './ErrorDialog';
import { CircularProgress } from '../Utilities/CircularProgress';
import { isMobileDevice } from '../common/utilities';

class Dialog extends Component {
  static propTypes = {
    open: PropTypes.bool,
    preventable: PropTypes.bool,
    closeIcon: PropTypes.bool,
    disableOnCheck: PropTypes.bool,
    checkboxMessage: PropTypes.string,
    saveButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
    confirmButtonText: PropTypes.string,
    type: PropTypes.oneOf(['confirm', 'success', 'error']),
    titleAlign: PropTypes.oneOf(['center', 'left', 'right']),
    contentAlign: PropTypes.oneOf(['center', 'left', 'right']),
    footerAlign: PropTypes.oneOf(['center', 'left', 'right']),
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xlg']),
    className: PropTypes.string,
    classes: PropTypes.shape({}),
    shouldRenderAction: PropTypes.bool,
    onRequestSave: PropTypes.func,
    onRequestClose: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    children: PropTypes.node,
    contentHeight: PropTypes.object,
    titleStyles: PropTypes.object
  };

  static defaultProps = {
    type: '',
    open: false,
    preventable: false,
    closeIcon: true,
    disableOnCheck: false,
    checkboxMessage: null,
    title: null,
    titleAlign: 'center',
    contentAlign: 'center',
    footerAlign: 'right',
    size: 'md',
    className: '',
    style: {},
    shouldRenderAction: true,
    onRequestSave: _noop,
    onRequestClose: _noop,
    children: null,
    saveButtonText: 'Save',
    cancelButtonText: 'Cancel',
    confirmButtonText: 'Close',
    classes: {},
    titleStyles: {},
    contentStyles: {}
  };

  state = {
    open: this.props.open,
    isChecked: false
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.open !== nextProps.open) {
      this.toggleOpenModal(nextProps.open);
    }
  }

  onSuccess = () =>
    Promise.resolve(
      this.props.onRequestSave({ isChecked: this.state.isChecked })
    ).then(() => this.toggleOpenModal(false));

  onCancel = () =>
    Promise.resolve(this.props.onRequestClose()).then(() =>
      this.toggleOpenModal(false)
    );

  toggleOpenAnimation(filter, value) {
    this.setState({ [filter]: value });
  }

  toggleOpenModal = (open = true) => {
    this.setState({ open });
  };

  defaultInnerDialog() {
    const {
      children,
      preventable,
      disableOnCheck,
      checkboxMessage,
      contentAlign,
      footerAlign,
      saveButtonText,
      cancelButtonText,
      contentStyles,
      classes,
      height
    } = this.props;

    return (
      <div>
        <div
          className={classes.maximusDialogContent}
          style={{ textAlign: contentAlign, ...contentStyles }}
        >
          <div>{children}</div>
          {(preventable || disableOnCheck) && (
            <div className={classes.maximusDialogCheckbox}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.isChecked}
                    onChange={(event) =>
                      this.setState({ isChecked: event.target.checked })
                    }
                    value="isChecked"
                    color="primary"
                  />
                }
                label={checkboxMessage}
              />
            </div>
          )}
        </div>

        {this.props.shouldRenderAction && (
          <div className={cn(classes.maximusDialogFooter, footerAlign)}>
            <Button
              onClick={this.onCancel}
              variant="contained"
              style={{
                backgroundColor: colors.lenoxDark2,
                color: 'white',
                minWidth: '40%',
                margin: '0 5%',
                height: height || ''
              }}
            >
              {cancelButtonText}
            </Button>
            <Button
              onClick={this.onSuccess}
              variant="contained"
              style={{
                backgroundColor: colors.lenoxSuccess1,
                color: 'white',
                minWidth: '40%',
                margin: '0 5%',
                height: height || ''
              }}
            >
              {saveButtonText}
            </Button>
          </div>
        )}
      </div>
    );
  }

  renderInnerDialogContent() {
    let innerDialog;

    switch (this.props.type) {
      case 'confirm':
        innerDialog = (
          <ConfirmDialog
            {...this.props}
            classes={this.props.classes}
            toggleOpenModal={this.toggleOpenModal}
          />
        );
        break;
      case 'success':
        innerDialog = (
          <SuccessDialog
            {...this.props}
            classes={this.props.classes}
            toggleOpenModal={this.toggleOpenModal}
          />
        );
        break;
      case 'error':
        innerDialog = (
          <ErrorDialog
            {...this.props}
            classes={this.props.classes}
            toggleOpenModal={this.toggleOpenModal}
          />
        );
        break;
      default:
        // if type isn't specified, render original inner dialog content for now
        innerDialog = this.defaultInnerDialog();
    }
    return innerDialog;
  }

  render() {
    const { entering, exiting } = this.state;
    const {
      classes,
      size,
      className,
      title,
      titleAlign,
      fixedHeight = false,
      style = {},
      titleStyles = {},
      onCloseBackdropFunc
    } = this.props;
    return (
      <ModalDialog
        classes={(fixedHeight && { paper: classes.dialogPaper }) || classes}
        open={this.state.open}
        style={style || {}}
        onClose={onCloseBackdropFunc || this.onCancel}
        slotProps={{
          backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.64)' } }
        }}
        onExit={() => this.toggleOpenAnimation('exiting', true)}
        onExited={() => this.toggleOpenAnimation('exiting', false)}
        PaperProps={{
          className: cn(
            classes.maximusDialogPaper,
            size,
            className,
            entering && classes.maximusDialogPaper.entering,
            exiting && classes.maximusDialogPaper.exiting
          )
        }}
      >
        {title && (
          <div
            className={classes.maximusDialogTitle}
            style={{ textAlign: titleAlign, ...titleStyles }}
          >
            {title}
          </div>
        )}

        {(!!this.props.loading && CircularProgress(80, classes)) ||
          this.renderInnerDialogContent()}
      </ModalDialog>
    );
  }
}

/** Animation for exiting */
const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

/** Animation for entering */
const bounceIn = keyframes`
 0%, 20%, 40%, 60%, 80%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
  40% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  75% {
    transform: scale3d(.95, .95, .95);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
`;

export const styles = () => ({
  loader: {
    marginTop: '20px',
    marginLeft: isMobileDevice() ? '80px' : '260px'
  },
  dialogPaper: {
    minHeight: '30vh',
    maxHeight: '30vh'
  },

  maximusDialogPaper: {
    outline: 'none',
    minWidth: '290px',
    overflow: 'hidden',
    width: '100%',
    borderRadius: '10px !important',

    '&.sm': {
      maxWidth: '350px'
    },
    '&.md': {
      maxWidth: '600px'
    },
    '&.lg': {
      maxWidth: '800px'
    },
    '&.xlg': {
      maxWidth: '1200px'
    },

    '&.entering': {
      animationName: bounceIn,
      animationDuration: '0.35s',
      animationFillMode: 'both'
    },
    '&.exiting': {
      animation: `${fadeOut} 0.25s`
    },

    '& .maximus-dialog-close-icon': {
      position: 'absolute !important',
      top: '10px',
      right: '10px'
    }
  },

  maximusDialogTitle: {
    position: 'relative',
    padding: '2.5rem 1rem 0',
    fontSize: '2rem'
  },

  maximusDialogContent: {
    padding: '2rem 1rem',
    maxHeight: 'calc(100vh - 100px)',
    overflowX: 'hidden',
    overflowY: 'auto'
  },

  maximusDialogCheckbox: {
    marginBottom: '-15px',
    paddingTop: '5px'
  },

  maximusDialogFooter: {
    '&.left': {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      padding: '0.5rem 1rem 2rem  1rem'
    },
    '&.right': {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      padding: '0.5rem 1rem 2rem  1rem'
    },
    '&.center': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0.5rem 1rem 2rem  1rem'
    }
  }
});

export default withStyles(Dialog, styles);
