/* eslint-disable import/no-cycle */
import { expectArrayData } from './commonRequests';

export const getDataSorted = (data, key, order = 1) =>
  expectArrayData(data).sort((object1, object2) =>
    order === 1
      ? Math.floor(parseFloat(object2[key]) - parseFloat(object1[key]))
      : Math.floor(parseFloat(object1[key]) - parseFloat(object2[key]))
  );

export const getNanDataSorted = (data, key) =>
  expectArrayData(data).sort((object1, object2) => {
    if (object1[key] > object2[key]) return 1;
    if (object1[key] < object2[key]) return -1;
    return 0;
  });

export const has = Object.prototype.hasOwnProperty;

export const firstEightChar = (str) => str.substring(0, 8);

export const formatXAxis = (value) =>
  value.length > 8 ? `${firstEightChar(value)}...` : value;

export const isMobileDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
      userAgent
    ) ||
    typeof window.orientation !== 'undefined' ||
    navigator.userAgent.indexOf('IEMobile') !== -1
  );
};

export const isDesktop = () => !isMobileDevice();

export const detectTablet = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
    userAgent
  );
};

export const capitalizeFirst = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const capitalizeWords = (string) => {
  const text = string.toLowerCase();
  return text.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, function ($1) {
    return $1.toUpperCase();
  });
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};
