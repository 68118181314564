/* eslint-disable default-param-last */
/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import { Table, TableBody, TableRow, TableCell, Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import BarChart from '../../../Charts/BarChart';
import {
  convertToFormattedNumber,
  numberWithCommas
} from '../../../TableContainer/TableUtilities/columnFormatter';
import { colors } from '../../../../Utilities/LenoxColors';
import { checkAffiliate } from '../../../PermissionsWrappers/permissionChecks';
import { getDataSorted } from '../../../common/utilities';

const styles = () => ({
  tableCell: {
    width: '50%'
  },
  tableContainer: {
    border: 'solid 1px rgba(20,20,20,.3)',
    borderRadius: '8px',
    margin: '1rem'
  },
  table: {},
  tableTitle: {
    fontSize: '1.25rem',
    textAlign: 'center',
    padding: '.5rem',
    backgroundColor: colors.lenoxDark2,
    color: colors.lenoxLight1,
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px'
  }
});

const revBreakdownMapping = [
  { name: 'agent_revenue', display: 'Agent' },
  { name: 'aggregator_revenue', display: 'Aggregator' },
  { name: 'email_revenue', display: 'Email' },
  { name: 'lb_revenue', display: 'LB' },
  { name: 'phoneRevenue', display: 'Phone' },
  { name: 'sms_revenue', display: 'SMS' },
  { name: 'ty_revenue', display: 'TY' },
  { name: 'warranty_total', display: 'Warranty' }
];

const revReturnsMapping = [
  {
    value: 'to_return',
    display: 'Amount Returned',
    formatter: (val) => `$${convertToFormattedNumber(val)}`
  },
  {
    value: 'unique_users_returns',
    display: 'Returned Count',
    formatter: (val) => `${numberWithCommas(val)}`
  }
];

const soldMapping = [
  {
    name: 'aggregatorCount',
    display: 'Aggregator',
    formatter: (val) => `${numberWithCommas(val)}`
  },
  {
    name: 'agentCount',
    display: 'Agent',
    formatter: (val) => `${numberWithCommas(val)}`
  },
  {
    name: 'phoneSales',
    display: 'Phone',
    formatter: (val) => `${numberWithCommas(val)}`
  },
  {
    name: 'total_sold',
    display: 'Total Sold',
    formatter: (val) => `${numberWithCommas(val)}`
  },
  {
    name: 'unsold',
    display: 'Unsold',
    formatter: (val) => `${numberWithCommas(val)}`
  }
];

const conversionMapping = [
  {
    value: 'converted',
    display: 'Form Converted',
    formatter: (val) => `${numberWithCommas(val)}`
  },
  {
    value: 'form_conversion_percent',
    display: 'Form Converted %',
    formatter: (val) => `${numberWithCommas(val)}%`
  }
];

const profitMapping = [
  {
    value: 'final_profit',
    display: 'Profit With Returns',
    formatter: (val) => `$${convertToFormattedNumber(val)}`
  }
];

const epcMapping = [
  {
    value: 'epc',
    display: 'EPC',
    formatter: (val) => `$${convertToFormattedNumber(val)}`
  },
  {
    value: 'buyer_epc',
    display: 'Buyer EPC',
    formatter: (val) => `$${convertToFormattedNumber(val)}`
  }
];

const peakNonPeak = [
  {
    value: 'peak',
    display: 'Peak',
    formatter: (val) => `${numberWithCommas(val)}`
  },
  {
    value: 'non_peak',
    display: 'Off Peak',
    formatter: (val) => `${numberWithCommas(val)}`
  }
];

class SiteBreakdown extends Component {
  assignValuesToMapping = (mapping) => {
    const tempMapping = Object.assign([], mapping);
    const map = tempMapping.map((item) => {
      if (this.props.row[item.name]) {
        item.value = this.props.row[item.name];
      }
      return item;
    });
    return getDataSorted(map, 'value');
  };

  renderChart = (title, mapping, label) => (
    <Grid item xs={10} className={this.props.classes.tableContainer}>
      <div className={this.props.classes.tableTitle}>{title}</div>
      <div style={{ padding: '2rem' }}>
        <BarChart
          data={this.assignValuesToMapping(mapping).map((row) => ({
            ...row,
            value: +row.value
          }))}
          barNameKey="display"
          bars={[
            {
              dataKey: 'value',
              label,
              color: colors.lenoxSuccess1
            }
          ]}
          showDollar={label !== 'Count'}
          hideDecimal={label === 'Count'}
          loading={false}
        />
      </div>
    </Grid>
  );

  renderTableCell = (data, className = 'tableCell') => (
    <TableCell className={this.props.classes[className]}>{data}</TableCell>
  );

  renderRow = (data, defaultVal) => (
    <TableRow>
      {this.renderTableCell(data.display)}
      {this.renderTableCell(
        this.props.row && this.props.row[data.value]
          ? data.formatter(this.props.row[data.value])
          : defaultVal
      )}
    </TableRow>
  );

  renderTable = (title, dataMapping = [], defaultVal) => (
    <Grid item xs={10} className={this.props.classes.tableContainer}>
      <div className={this.props.classes.tableTitle}>{title}</div>
      <Table className={this.props.classes.table}>
        <TableBody>
          {dataMapping.map((data) => this.renderRow(data, defaultVal))}
        </TableBody>
      </Table>
    </Grid>
  );

  render() {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: '5rem' }}
      >
        {!checkAffiliate() &&
          this.renderChart('Revenue', revBreakdownMapping, 'Revenue')}
        {!checkAffiliate() &&
          this.renderChart('Sales Count', soldMapping, 'Count')}
        {!checkAffiliate() &&
          this.renderTable('Revenue Returns', revReturnsMapping, '0')}
        {!checkAffiliate() &&
          this.renderTable('Profit With Returns', profitMapping, '$0.00')}
        {this.renderTable('Forms Converted', conversionMapping, '0')}
        {!checkAffiliate() && this.renderTable('EPC', epcMapping, '$0.00')}
        {!checkAffiliate() &&
          this.renderTable('Peak - Off Peak', peakNonPeak, '0')}
      </Grid>
    );
  }
}

export default withStyles(SiteBreakdown, styles);
