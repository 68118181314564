/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Button } from '@mui/material';
import { handleChange as updateFilters } from '../../../Filters/actions/actions';
import {
  handleChange as updatePage,
  updateBreadCrumbs
} from '../../../PageContainer/actions';
import Table from '../../../TableContainer/Table';
import { renderReportLink } from '../../../TableContainer/TableUtilities/RenderReportLink';
import { saveToLocalStorage } from '../../../Utilities/saveStateHelper';
import { getColumnWidths } from '../../../TableContainer/TableUtilities/getColumnWidths';
import { getColumnCompare } from '../../../TableContainer/TableUtilities/columnFormatter';
import { checkAffiliate } from '../../../PermissionsWrappers/permissionChecks';
import { getReportReports } from '../../requests';
import { getTableDataWithMostUpdated } from '../../../TableContainer/TableUtilities/buildTableRequest';
import {
  renderDefaultCell,
  renderCurrencyCell,
  renderPercentCell,
  renderProfit,
  renderNumberCell,
  columnTooltip
} from '../../../TableContainer/TableUtilities/defaultCells';
import BarChart from '../../../Charts/BarChart';
import ActionDrawer from '../../../PageContainer/ActionDrawer';
import SiteBreakdown from './SiteBreakdown';
import { colors } from '../../../../Utilities/LenoxColors';
import ChartsWrapper from '../../../Charts/ChartsWrapper';
import {
  handleGetDataChange,
  handleLastUpdatedChange
} from '../../../lenox/actions';
import { getMargin } from '../../../Utilities/getMargin';
import { swapElements } from '../../../Utilities/swapElements';
import { withRouter } from '../../../Utilities/withRouter';
import { logError } from '../../../../Utilities/logError';
import { ROUTE_NAMES } from '../../../NavBar/NavigationContainer';

const totalMapping = {
  vertical: { type: 'header' },
  unique_users: { type: 'number' },
  final_revenue: { type: 'currency' },
  cost: { type: 'currency' },
  final_profit: { type: 'profit' },
  total_sold: { type: 'number' },
  margin: {
    type: 'percent',
    dividend: 'final_profit',
    divisor: 'final_revenue',
    profitColor: true
  },
  converted: { type: 'number' },
  form_conversion_percent: {
    type: 'percent',
    dividend: ['converted'],
    divisor: 'zip_converted'
  },
  act: { type: 'nothing' },
  sold_percent: {
    type: 'percent',
    dividend: 'total_sold',
    divisor: 'converted'
  },
  alv: {
    type: 'average_currency',
    dividend: 'final_revenue',
    divisor: 'converted'
  },
  alvPlus: {
    type: 'average_currency',
    dividend: 'final_revenue',
    divisor: 'converted'
  },
  breakdown: { type: 'nothing' }
};

const columns = [
  { name: 'vertical', title: 'Vertical', width: 200 },
  { name: 'unique_users', title: 'Uniques', compare: 'priority', width: 130 },
  {
    name: 'final_revenue',
    title: columnTooltip(
      'Estimated Revenue',
      'Estimated Revenue counts towards the date a user first visits our site.'
    ),
    compare: 'priority',
    width: 170
  },
  { name: 'cost', title: 'Cost', compare: 'priority', width: 170 },
  { name: 'final_profit', title: 'Profit', compare: 'priority', width: 170 },
  { name: 'margin', title: 'Margin', compare: 'priority', width: 100 },
  { name: 'converted', title: 'Form Conv.', compare: 'priority', width: 120 },
  {
    name: 'form_conversion_percent',
    title: 'Form Conv %',
    compare: 'priority'
  },
  { name: 'act', title: 'ACT', width: 120 },
  { name: 'sold_percent', title: 'Sold %', compare: 'priority', width: 120 },
  { name: 'alv', title: 'RPC', compare: 'priority', width: 120 },
  { name: 'alvPlus', title: 'RPC+', compare: 'priority', width: 120 },
  { name: 'breakdown', title: 'Breakdown', width: 135 }
];

if (checkAffiliate()) {
  columns[3].title = 'Revenue';
  columns.splice(2, 1);
  columns.splice(3, 1);
  columns.splice(3, 1);
  columns.splice(5, 1);
  columns.splice(5, 1);
  columns.splice(5, 1);
  columns.splice(5, 1);
  columns.splice(5, 1);
  columns.splice(5, 1);

  columns[0].width = undefined;
  columns[1].width = undefined;
  columns[2].width = undefined;
  columns[3].width = undefined;
  columns[4].width = undefined;
}

const buildTableParams = (action, props) => ({
  action,
  start_date: moment(props.startDate).format('YYYY-MM-DD'),
  end_date: moment(props.endDate).format('YYYY-MM-DD'),
  date_range: props.dateRange,
  peak: props.peak
});

class SiteTotals extends Component {
  state = {
    rows: [],
    loading: true,
    loadingError: false,
    breakdownRow: null
  };

  requestTime = React.createRef();

  getRequestTime = () => this.requestTime.current.toString();

  componentDidMount() {
    this.getData();
    this.props.handleGetDataChange(this.getData);
  }

  componentWillUnmount() {
    this.props.handleLastUpdatedChange('');
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(
        buildTableParams('getSiteTotals', this.props),
        buildTableParams('getSiteTotals', prevProps)
      )
    ) {
      this.getData();
      this.props.handleLastUpdatedChange('');
    }
  }

  getData = () => {
    const requestTime = new Date().getTime() / 1000;
    this.requestTime.current = requestTime;
    this.setState({ loading: true, loadingError: false }, () => {
      getTableDataWithMostUpdated(
        buildTableParams('getSiteTotals', this.props),
        getReportReports,
        (values) => {
          if (requestTime.toString() === this.getRequestTime()) {
            values.rows.map((row) => {
              return (row.margin = getMargin(
                row.final_profit,
                row.final_revenue
              ));
            });
            this.setState(values);
            const { lastUpdated } = values;
            this.props.handleLastUpdatedChange(lastUpdated);
          }
        }
      ).catch((error) => {
        logError(error);
        this.setState({ rows: [], loading: false, loadingError: true });
      });
    });
  };

  renderBreakdownButton = (row) => (
    <div>
      <Button
        variant="contained"
        sx={{
          fontSize: '.75rem',
          backgroundColor: colors.lenoxSuccess1,
          color: colors.lenoxLight1
        }}
        style={{ width: '100%' }}
        onClick={() => this.changeBreakdownRow(row)}
      >
        Breakdown
      </Button>
    </div>
  );

  changeBreakdownRow = (row) => {
    this.setState({ breakdownRow: row });
  };

  setBreadCrumb = () => {
    const { breadCrumbs } = this.props;
    this.props.updateBreadCrumbs(
      breadCrumbs,
      'Affiliates',
      'site_totals',
      'Sites',
      'affiliate_reporting'
    );
  };

  setSiteFilter = (siteId) => {
    saveToLocalStorage(`affiliate_reportingsite`, siteId);
    this.props.updateFilters('site', siteId);
  };

  setCrumbsAndFilters = (site) => {
    saveToLocalStorage('site_filter', site);

    this.setSiteFilter(site);
    this.setBreadCrumb();
  };

  redirectReport = (page, route = false) => {
    this.props.updatePage('selectedPage', page);
    this.props.router.navigate(`/${route || ROUTE_NAMES.Reporting}/${page}`);

    if (route) {
      saveToLocalStorage(`${route}selectedPage`, page);
    }
  };

  cellComponent = ({ row, column }) => {
    switch (column.name) {
      case 'vertical':
        return renderReportLink(
          row[column.name],
          row.site_id_num,
          (value) => value,
          this.setCrumbsAndFilters,
          () => this.redirectReport('affiliate_reporting')
        );
      case 'converted':
        return renderNumberCell(row[column.name]);
      case 'unique_users':
      case 'zip_converted':
        // return renderNumberCell(row[column.name], this.renderBreakdownDrawer(row, column.name));
        return renderNumberCell(row[column.name]);
      case 'return_percent':
      case 'sold_percent':
      case 'form_conversion_percent':
      case 'zip_conversion_percent':
        return renderPercentCell(row[column.name]);
      case 'total_revenue':
      case 'final_revenue':
      case 'alv':
      case 'alvPlus':
      case 'cost':
        return renderCurrencyCell(row[column.name]);
      case 'final_profit':
        return renderProfit(row[column.name], '$');
      case 'margin':
        return renderProfit(row[column.name], '', '%');
      case 'breakdown':
        return renderDefaultCell(this.renderBreakdownButton(row));
      default:
        return renderDefaultCell(row[column.name]);
    }
  };

  renderCharts = (chartData, loading) =>
    !checkAffiliate() &&
    !loading && (
      <ChartsWrapper testId="SiteTotalsBarChart">
        <BarChart
          data={swapElements(chartData, 4, 5).map((row) => ({
            ...row,
            profit: +row.profit
          }))}
          barNameKey="vertical"
          bars={[
            {
              dataKey: 'profit',
              label: 'Profit',
              color: colors.lenoxSuccess1,
              negativeColor: colors.lenoxNegative
            }
          ]}
          showDollar
        />
      </ChartsWrapper>
    );

  render() {
    const { rows, loading, loadingError, breakdownRow } = this.state;

    const chartData = rows.map((value) => {
      value.vertical = value.vertical.capitalize();
      return value;
    });

    return (
      <>
        {this.renderCharts(chartData, loading)}
        <Table
          loading={loading}
          rows={rows}
          columns={columns}
          drawerTitle="Sites"
          cellComponent={this.cellComponent}
          totalsMapping={totalMapping}
          defaultColumnWidths={getColumnWidths(columns)}
          getColumnCompare={getColumnCompare(columns)}
          tableColumnLocalStorageName="site_totals"
          defaultSortingOrder={[{ columnName: 'vertical', direction: 'asc' }]}
          loadingError={loadingError}
        />
        <ActionDrawer
          fullWidth
          hideButton
          drawerIsOpen={!!breakdownRow}
          onClose={() => this.changeBreakdownRow(null)}
          drawerTitle="Breakdown"
          content={breakdownRow ? <SiteBreakdown row={breakdownRow} /> : null}
        />
      </>
    );
  }
}

export default connect(
  (state) => ({
    startDate: state.dateRange.startDate,
    endDate: state.dateRange.endDate,
    dateRange: state.dateRange.dateRange,
    peak: state.dateRange.peak,
    breadCrumbs: state.pageReducer.breadCrumbs
  }),
  (dispatch) =>
    bindActionCreators(
      {
        updatePage,
        updateBreadCrumbs,
        updateFilters,
        handleGetDataChange,
        handleLastUpdatedChange
      },
      dispatch
    )
)(withRouter(SiteTotals));
