const siteOptions = [
  { value: 0, display: 'Select a Vertical' },
  { value: '2', display: 'Auto' },
  { value: '4', display: 'Home' },
  { value: '1', display: 'Life' },
  { value: '9', display: 'Final Expense' }
];

export const filters = () => ({
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions,
    value: '2',
    inputWidth: 3
  }
});
