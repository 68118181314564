import React, { Component } from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Grid,
  Button
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import PieChart from '../../../Charts/PieChart';
import { colors } from '../../../../Utilities/LenoxColors';
import { NetworkRequest } from '../../../Utilities/NetworkRequests/NetworkRequests';
import LoaderComponent from '../../../Utilities/LoaderComponent';

const styles = () => ({
  tableCell: {
    width: '50%',
    wordWrap: 'break-word'
  },
  tableCellHead: {
    fontWeight: 'bold'
  },
  tableContainer: {
    border: 'solid 1px rgba(20,20,20,.3)',
    borderRadius: '8px',
    margin: '1rem'
  },
  table: {
    overflowX: 'scroll'
  },
  bigTable: {
    maxHeight: 600,
    overflowY: 'scroll'
  },
  tableTitle: {
    fontSize: '1.25rem',
    textAlign: 'center',
    padding: '.5rem',
    backgroundColor: colors.lenoxDark2,
    color: colors.lenoxLight1,
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px'
  }
});

const referrersMapping = [
  { value: 'clicks', display: 'Clicks' },
  { value: 'referrer', display: 'Referrer' }
];
/*
const userAgentMapping = [
  { value: 'clicks', display: 'Clicks' },
  { value: 'user_agent', display: 'User Agent' },
];
*/
class ClicksConversionsBreakdown extends Component {
  state = {
    loading: true,
    data: [],
    hideData: {
      referrer: false,
      user_agent: false
    }
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { type, row, params } = this.props;
    this.setState({ loading: true });
    const finalParams = { ...params, type };
    if (!finalParams.site_id) {
      finalParams.site_id = row.site_id_num;
    }
    finalParams.affiliate_id = row.affiliate_id || '';
    const { data } = await NetworkRequest('reports', finalParams);

    this.setState({
      data,
      loading: false,
      hideData: {
        referrer: data.referrer.length > 10,
        user_agent: data.user_agent.length > 10
      }
    });
  };

  renderChart = (title, dataKey, nameKey) => (
    <Grid item xs={10} className={this.props.classes.tableContainer}>
      <h2 className={this.props.classes.tableTitle}>{title}</h2>
      <div style={{ padding: '2rem' }}>
        <PieChart
          data={this.state.data[dataKey]}
          dataKey="clicks"
          nameKey={nameKey}
          hideLowLabel={false}
          formatValue={false}
        />
      </div>
    </Grid>
  );

  renderTableCell = (data, className = 'tableCell') => (
    <TableCell className={this.props.classes[className]}>{data}</TableCell>
  );

  renderRow = (row, dataMapping) => (
    <TableRow>
      {dataMapping.map((column) => this.renderTableCell(row[column.value]))}
    </TableRow>
  );

  renderTable = (title, dataKey, dataMapping, hideData) => (
    <Grid item xs={10} className={this.props.classes.tableContainer}>
      <h2 className={this.props.classes.tableTitle}>{title}</h2>
      <div
        className={`${this.props.classes.table} ${
          !hideData ? this.props.classes.bigTable : ''
        }`}
      >
        <Table>
          <TableHead>
            <TableRow>
              {dataMapping.map((column) =>
                this.renderTableCell(column.display, 'tableCellHead')
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {hideData
              ? this.state.data[dataKey]
                  .slice(0, 10)
                  .map((row) => this.renderRow(row, dataMapping))
              : this.state.data[dataKey].map((row) =>
                  this.renderRow(row, dataMapping)
                )}
          </TableBody>
        </Table>
      </div>
      {hideData && (
        <Button
          fullWidth
          variant="contained"
          onClick={() =>
            this.setState(({ hideData }) => ({
              hideData: { ...hideData, [dataKey]: false }
            }))
          }
        >
          Show All
        </Button>
      )}
    </Grid>
  );

  render() {
    const { loading, hideData } = this.state;
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: '5rem' }}
      >
        {loading ? (
          <LoaderComponent padding={100} />
        ) : (
          <>
            {this.renderTable(
              'Referrers',
              'referrer',
              referrersMapping,
              hideData.referrer
            )}
            {this.renderChart('Geolocations', 'states', 'state')}
            {/* {this.renderTable('User Agents', 'user_agent', userAgentMapping, hideData.user_agent)} */}
          </>
        )}
      </Grid>
    );
  }
}

export default withStyles(ClicksConversionsBreakdown, styles);
