export const LEAD_RESULTS = [
  { value: 'all', display: 'All' },
  { value: 'sold', display: 'Sold' },
  { value: 'unsold', display: 'Unsold' }
];

export const SALES_TYPE = [
  { value: 'all', display: 'All' },
  { value: 'aggregator', display: 'Aggregator' },
  { value: 'agent', display: 'Agent' },
  { value: 'warranty', display: 'Warranty' }
];

export const USER_TYPES = [
  { value: 'all', display: 'Select Type' },
  { value: '1', display: 'Admin' },
  { value: '2', display: 'External Affiliate' },
  { value: '3', display: 'Agent' },
  { value: '4', display: 'Internal Affiliate' },
  { value: '5', display: 'Accounting' },
  { value: '6', display: 'Affiliate Manager' },
  { value: '7', display: 'Tech' },
  { value: '8', display: 'Power User' },
  { value: '9', display: 'AMB' }
];

export const PIXEL_TYPES = [
  { value: '-1', display: 'Select Type' },
  { value: 'iframe', display: 'iFrame' },
  { value: 'postback', display: 'Postback' },
  { value: 'js_script', display: 'JS Script' },
  { value: 'img', display: 'Image' }
];

export const PIXEL_LOCATION = [
  { value: '-1', display: 'Select Location' },
  { value: 'anywhere', display: 'Anywhere' },
  { value: 'head', display: 'Head' },
  { value: 'body', display: 'Body' }
];

export const PIXEL_STEP = [
  { value: '-1', display: 'Select Step' },
  { value: 'presell', display: 'Presell' },
  { value: 'form', display: 'Form' },
  { value: 'ty', display: 'Thank You' }
];

export const SPEND_STEP = [
  { value: '0', display: 'Select Step' },
  { value: 'landing', display: 'Landing' },
  { value: 'form', display: 'Form' },
  { value: 'quotes', display: 'Quotes' },
  { value: 'ma_click', display: 'Media Alpha Click' }
];

export const ASSET_TYPES = [
  { value: 'all', display: 'Select Asset Type' },
  { value: 'logo', display: 'Logo' },
  { value: 'background_image', display: 'Background Image' },
  { value: 'header', display: 'Header Text' },
  { value: 'call_to_action', display: 'Call to Action Text' }
];

export const ASSET_TYPES_PRESELL = [
  { value: 'all', display: 'Select Asset Type' },
  { value: 'top_image', display: 'Top Image' },
  { value: 'side_image', display: 'Side Image' },
  { value: 'bottom_image', display: 'Bottom Image' },
  { value: 'header', display: 'Header Text' },
  { value: 'call_to_action', display: 'Call to Action Text' }
];

export const ASSET_LOCATIONS = [
  { value: 'landing', display: 'Landing' },
  { value: 'presell', display: 'Presell' }
];

export const DEVICES = [
  { value: 'all', display: 'All devices' },
  { value: 'mobile', display: 'Mobile' },
  { value: 'desktop', display: 'Desktop' }
];

export const DEVICE_OPTIONS = [
  { value: 'all', display: 'All devices' },
  { value: '1', display: 'Desktop' },
  { value: '2', display: 'Mobile' },
  { value: '3', display: 'Tablet' }
];

export const PLACEMENT_SOURCES = [
  { value: 'all', display: 'All' },
  { value: 'ty', display: 'TY' },
  { value: 'lb', display: 'LB' }
];

export const FORM_DATA_OPTIONS = [
  { value: 'all', display: 'All Forms' },
  { value: '0', display: 'Full Data Forms' },
  { value: '1', display: 'Partial Data Forms' }
];

export const INSURANCE_OPTIONS = [
  { value: '1', display: 'Insured' },
  { value: '0', display: 'Uninsured' }
];

export const OWNERSHIP_OPTIONS = [
  { value: '1', display: 'Yes' },
  { value: '0', display: 'No' }
];

export const VEHICLE_OPTIONS = [
  { value: '1', display: 'Multiple driver/vehicle' },
  { value: '0', display: 'One driver/vehicle' }
];

export const MULTI_VEHICLE_OPTIONS = [
  { value: 0, display: 'Single Vehicle' },
  { value: 1, display: 'Multiple Vehicle' }
];

export const MULTI_DRIVER_OPTIONS = [
  { value: 0, display: 'Single Driver' },
  { value: 1, display: 'Multiple Driver' }
];

export const AGE_GROUP_OPTIONS = [
  { display: 'Under 21', value: 1 },
  { display: '21-25', value: 2 },
  { display: '26-30', value: 3 },
  { display: '31-35', value: 4 },
  { display: '36-40', value: 5 },
  { display: '41-45', value: 6 },
  { display: '46-50', value: 7 },
  { display: '51-55', value: 8 },
  { display: '56-60', value: 9 },
  { display: '61-65', value: 10 },
  { display: '66-70', value: 11 },
  { display: '71-75', value: 12 },
  { display: '76-80', value: 13 },
  { display: '81-85', value: 14 },
  { display: '86-90', value: 15 },
  { display: '91-95', value: 16 },
  { display: '96+', value: 17 }
];

export const CREDIT_RATING_OPTIONS = [
  { display: 'Poor', value: 1 },
  { display: 'Average', value: 3 },
  { display: 'Good', value: 4 },
  { display: 'Excellent', value: 5 }
];

export const STATES_OPTIONS = [
  { display: 'Unknown', value: 0 },
  { display: 'Alabama', value: 1 },
  { display: 'Alaska', value: 2 },
  { display: 'Arizona', value: 3 },
  { display: 'Arkansas', value: 4 },
  { display: 'California', value: 5 },
  { display: 'Colorado', value: 6 },
  { display: 'Connecticut', value: 7 },
  { display: 'Delaware', value: 8 },
  { display: 'District of Columbia', value: 9 },
  { display: 'Florida', value: 10 },
  { display: 'Georgia', value: 11 },
  { display: 'Hawaii', value: 12 },
  { display: 'Idaho', value: 13 },
  { display: 'Illinois', value: 14 },
  { display: 'Indiana', value: 15 },
  { display: 'Iowa', value: 16 },
  { display: 'Kansas', value: 17 },
  { display: 'Kentucky', value: 18 },
  { display: 'Louisiana', value: 19 },
  { display: 'Maine', value: 20 },
  { display: 'Maryland', value: 21 },
  { display: 'Massachusetts', value: 22 },
  { display: 'Michigan', value: 23 },
  { display: 'Minnesota', value: 24 },
  { display: 'Mississippi', value: 25 },
  { display: 'Missouri', value: 26 },
  { display: 'Montana', value: 27 },
  { display: 'Nebraska', value: 28 },
  { display: 'Nevada', value: 29 },
  { display: 'New Hampshire', value: 30 },
  { display: 'New Jersey', value: 31 },
  { display: 'New Mexico', value: 32 },
  { display: 'New York', value: 33 },
  { display: 'North Carolina', value: 34 },
  { display: 'North Dakota', value: 35 },
  { display: 'Ohio', value: 36 },
  { display: 'Oklahoma', value: 37 },
  { display: 'Oregon', value: 38 },
  { display: 'Pennsylvania', value: 39 },
  { display: 'Rhode Island', value: 40 },
  { display: 'South Carolina', value: 41 },
  { display: 'South Dakota', value: 42 },
  { display: 'Tennessee', value: 43 },
  { display: 'Texas', value: 44 },
  { display: 'Utah', value: 45 },
  { display: 'Vermont', value: 46 },
  { display: 'Virginia', value: 47 },
  { display: 'Washington', value: 48 },
  { display: 'West Virginia', value: 49 },
  { display: 'Wisconsin', value: 50 },
  { display: 'Wyoming', value: 51 }
];

export const PRESETS_OPTIONS = [
  { value: '0', display: 'Select a preset' },
  { value: '5', display: 'No preset' },
  {
    value: '1',
    display:
      'Multiple driver/vehicle, insured, homeowner, above average credit, ages between 21 and 65'
  },
  {
    value: '2',
    display:
      'Multiple driver/vehicle, insured, homeowner, above average credit, ages under 21 and over 65'
  },
  { value: '3', display: 'Uninsured, renter, below average credit' },
  {
    value: '4',
    display: '1 driver/vehicle, insured, homeowner, above average credit'
  }
];

export const EXCLUDE_BUYER_TYPE_OPTIONS = [
  { value: 0, display: 'All Types' },
  { value: 1, display: 'Exclude TY, Leave Behind, and Phone' },
  { value: 2, display: 'Exclude Aggregators and Ping Tree' },
  { value: 3, display: 'Exclude TY and Leave Behind' }
];

export const TIME_PEAK_OPTIONS = [
  { value: 'all', display: 'All' },
  { value: 'peak', display: 'Peak (9AM - 5PM EST)' },
  { value: 'off_peak', display: 'Off Peak (5PM - 9AM EST)' }
];
