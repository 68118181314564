import {
  siteOptions,
  affiliateID,
  getTrafficSources
} from '../../../../common/commonRequests';
import { checkAmb } from '../../../../PermissionsWrappers/permissionChecks';

const subIDValues = [
  { value: 1, display: 'S1' },
  { value: 2, display: 'S2' },
  { value: 3, display: 'S3' },
  { value: 4, display: 'S4' },
  { value: 5, display: 'S5' }
];

export const filters = () => ({
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions(),
    value: '2',
    inputWidth: 3
  },
  affiliateID: {
    name: 'affiliateID',
    type: 'autocomplete',
    label: 'Affiliate',
    options: () => affiliateID(true),
    value: 'all',
    inputWidth: 3,
    placeholder: 'Please Select an Affiliate'
  },
  subID: {
    name: 'subID',
    type: 'select',
    label: 'SubID',
    options: subIDValues,
    value: 1,
    inputWidth: 3
  },
  trafficSource: {
    name: 'trafficSource',
    type: 'select',
    label: 'Traffic Source',
    defaultVal: '0',
    value: '0',
    inputWidth: 3,
    options: () => getTrafficSources(),
    hidden: checkAmb()
  }
});
