import { type AxiosResponse } from 'axios';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { handleChange } from '../../Components/lenox/actions';

import { checkAgent } from '../../Components/PermissionsWrappers/permissionChecks';
import { NetworkRequest } from '../../Components/Utilities/NetworkRequests/NetworkRequests';
import { USER_TYPE } from '../../Components/common/permissionConstants';
import { logError } from '../logError';
import {
  callCheckToken,
  excludeLoginLogout,
  getUserSettings,
  redirectToAgentSetup,
  setFilters
} from '../utils/app';
import { useEffectOnce } from './useEffectOnce';
import { usePrevious } from './usePrevious';
import { config } from '../../config';

interface IStoreState {
  lenox: {
    loggedIn: boolean;
    agentSetup: boolean;
  };
}

type TimeoutType = ReturnType<typeof setTimeout>;

export const useAppStoreState = () => {
  return useSelector(
    createSelector(
      (state: IStoreState) => {
        const { loggedIn, agentSetup } = state?.lenox ?? {};

        return { loggedIn, agentSetup };
      },
      (obj) => obj
    )
  );
};

export const useApp = () => {
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [isCheckingSetup, setIsCheckingSetup] = useState(true);
  const [permissions, setPermissions] = useState<boolean | string>(false);

  const checkTokenInterval = useRef<TimeoutType>();
  const checkPermissionsInterval = useRef<TimeoutType>();

  const dispatch = useDispatch();
  const { loggedIn } = useAppStoreState();

  const prevLoggedIn = usePrevious(loggedIn);

  // TODO: Check if basename will be dynamic
  // useEffectOnce(() => {
  //   const parsedData = window.location.pathname.split('/');
  //   const domain = parsedData[1].toLowerCase();

  //   if (config.availablePrefixes.includes(domain)) {
  //     setBaseName(`/${domain}`);

  //     config.routeBaseName = `/${domain}`;
  //   }
  // });

  const checkToken = useCallback(async () => {
    const token = await callCheckToken();

    if (!token) {
      return;
    }

    dispatch(handleChange('loggedIn', token));

    setIsCheckingAuth(false);
  }, [dispatch]);

  const checkAgentSetup = useCallback(async () => {
    if (!(loggedIn && localStorage.getItem(USER_TYPE))) {
      return;
    }

    if (!checkAgent()) {
      dispatch(handleChange('agentSetup', true));
      setIsCheckingSetup(false);

      return;
    }

    if (localStorage.getItem('checkAgentStatus')?.toString() !== 'null') {
      dispatch(
        handleChange('agentSetup', !!localStorage.getItem('checkAgentStatus'))
      );

      setIsCheckingSetup(false);
      redirectToAgentSetup(!localStorage.getItem('checkAgentStatus'));

      return;
    }

    try {
      const { data }: AxiosResponse<string, string> = await NetworkRequest(
        'permissions',
        {
          action: 'checkAgentStatus'
        }
      );

      localStorage.setItem('checkAgentStatus', data);
      dispatch(handleChange('agentSetup', data));

      setIsCheckingSetup(false);
      redirectToAgentSetup(!!(!data || data.toString() !== 'true'));
    } catch (error) {
      logError(error);
    }
  }, [dispatch, loggedIn]);

  const checkAndSetPermissions = useCallback(async () => {
    if (!localStorage.getItem('token')) {
      return;
    }

    if (
      permissions &&
      localStorage.getItem(USER_TYPE) &&
      permissions !== localStorage.getItem(USER_TYPE) &&
      excludeLoginLogout()
    ) {
      dispatch(handleChange('loggedIn', false));
      window.location.assign(`${config.routeBaseName}/Logout`);

      return;
    }

    const { data }: AxiosResponse<string, string> = await NetworkRequest(
      'permissions',
      {
        action: 'checkPermissions'
      }
    );

    localStorage.setItem(USER_TYPE, data);

    setPermissions(data);
    await checkAgentSetup();
    setFilters(dispatch);
  }, [checkAgentSetup, dispatch, permissions]);

  useEffectOnce(() => {
    checkToken();
    getUserSettings(dispatch);
    checkAndSetPermissions();

    checkTokenInterval.current = setInterval(() => callCheckToken(), 60000);
    checkPermissionsInterval.current = setInterval(
      () => checkAndSetPermissions(),
      15000
    );

    return () => {
      clearInterval(checkTokenInterval.current);
      clearInterval(checkPermissionsInterval.current);
    };
  });

  useEffect(() => {
    if (prevLoggedIn ?? prevLoggedIn === loggedIn) {
      return;
    }

    callCheckToken();
    checkToken();
    checkAndSetPermissions();
    getUserSettings(dispatch);
  }, [checkAndSetPermissions, checkToken, dispatch, loggedIn, prevLoggedIn]);

  const isNotReady = isCheckingAuth || !permissions || isCheckingSetup;

  return useMemo(() => {
    return {
      isLoading: isNotReady && loggedIn
    };
  }, [isNotReady, loggedIn]);
};
