/* eslint-disable react/no-unused-state */
/* eslint-disable prefer-const */
import React, { Component } from 'react';
import { Button, Grid } from '@mui/material';
import { Parser } from 'json2csv';
import moment from 'moment';
import { colors } from '../../../Utilities/LenoxColors';
import { isMobileDevice } from '../../common/utilities';

class CSVButton extends Component {
  async downloadCSV(csvData) {
    let data;
    let filename;
    let link;
    const { params, columns } = this.props;
    const parser = new Parser({ fields: columns.map(({ name }) => name) });

    const csv = parser.parse(csvData);

    if (csv === null) return;

    let dateRange;

    if (!params.dateRange && params.date_range !== 'all_time' && !params.year) {
      dateRange = ` (${moment(params.start_date).format(
        'YYYY-MM-DD'
      )}  - ${moment(params.end_date).format('YYYY-MM-DD')})`;
    } else if (params.dateRange && params.dateRange !== 'all_time') {
      dateRange = ` (${moment(params.startDate).format(
        'YYYY-MM-DD'
      )}  - ${moment(params.endDate).format('YYYY-MM-DD')})`;
    } else if (params.year && !params.dateRange) {
      dateRange = ` ${params.year}`;
    } else {
      dateRange = ' (All Time)';
    }

    filename = `${this.props.filename + dateRange}.csv`;

    data = encodeURI(csv);

    if (!csv.match(/^data:text\/csv/i)) {
      data = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
    }

    columns.forEach(({ name, title, csvTitle }) => {
      data = data?.replace(name, csvTitle || title);
    });

    link = document.getElementById('downloadLink');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    link.click();
  }

  downloadCSVButtonClick = async () => {
    this.setState({ loading: true });

    try {
      await this.downloadCSV(this.props.data);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { showButton, marginTop, breakdown } = this.props;

    return showButton ? (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: marginTop || '-3rem' }}
      >
        <a
          style={{ width: 0, height: 0, display: 'hidden' }}
          id="downloadLink"
          href=" "
        >
          {' '}
        </a>
        <Grid
          item
          xs={12}
          style={{
            textAlign: isMobileDevice() ? 'center' : 'right',
            marginRight: breakdown ? '1rem' : 0
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={this.downloadCSVButtonClick}
            style={{
              backgroundColor: colors.lenoxDark2,
              fontSize: breakdown ? '0.75rem' : null
            }}
          >
            Download CSV
          </Button>
        </Grid>
      </Grid>
    ) : (
      <div />
    );
  }
}

export default CSVButton;
