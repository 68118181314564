import {
  affiliateID,
  getTrafficSources,
  siteOptions,
  buyerListWithinDateRange
} from '../../../../common/commonRequests';

const buyerStep = [
  { value: '-1', display: 'Ping & Post' },
  { value: 'ping', display: 'Ping' },
  { value: 'post', display: 'Post' }
];

export const filters = () => ({
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions(),
    value: '2',
    inputWidth: 3
  },
  affiliateID: {
    name: 'affiliateID',
    type: 'autocomplete',
    label: 'Affiliate',
    options: () => affiliateID(),
    value: 'all',
    inputWidth: 3
  },
  trafficSource: {
    name: 'trafficSource',
    type: 'select',
    label: 'Traffic Source',
    defaultVal: '0',
    value: '0',
    inputWidth: 3,
    options: () => getTrafficSources()
  },
  buyer_step: {
    name: 'buyer_step',
    type: 'select',
    label: 'Step',
    options: () => buyerStep,
    value: '-1',
    inputWidth: 3
  },
  buyer: {
    name: 'buyer',
    type: 'select',
    label: 'Buyer',
    options: (...args) => buyerListWithinDateRange(...args, false, false),
    value: '-1',
    inputWidth: 3,
    dependencyName: 'site',
    withinDateRange: true
  }
});
