import { type FC, memo } from 'react';
import {
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Bar,
  Legend,
  Cell,
  ResponsiveContainer
} from 'recharts';
import { type IBarDataItem, type IBarChartComponent } from './types';
import { CircularProgress } from '../../Utilities/CircularProgress';
import { isMobileDevice } from '../../common/utilities';
import { useBarUtils } from './utils';

const BarChartComponent: FC<IBarChartComponent> = memo((props) => {
  const {
    barChatData,
    xAxisProps,
    yAxisProps,
    tooltipFormatter,
    barTooltipFormatter
  } = useBarUtils(props);

  const { loading, data, bars, width: propsWidth, height: propsHeight } = props;

  const isMobile = isMobileDevice();

  if (loading) {
    return CircularProgress(45, {});
  }

  const minMobileWidth = window.innerWidth - 50;
  let mobileWidth = minMobileWidth;

  if (barChatData.length > 4) {
    mobileWidth = Math.ceil(barChatData.length / 4) * minMobileWidth;
  }

  const width = isMobile ? mobileWidth : propsWidth || 600;
  const height = propsHeight || 300;

  return (
    <ResponsiveContainer
      className="responsiveContainer"
      width={width}
      height={height}
    >
      <BarChart
        data={barChatData}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis {...xAxisProps} />
        <YAxis {...yAxisProps} />

        <Tooltip formatter={tooltipFormatter} />
        <Legend wrapperStyle={{ bottom: '-10px' }} />
        {bars.map(({ dataKey, label, color, negativeColor }) => {
          return (
            <Bar dataKey={dataKey} key={dataKey} name={label} fill={color}>
              {data.map((entry, index) => {
                const key = `cell-${index}-${dataKey}`;
                const cellFill =
                  negativeColor &&
                  parseFloat(`${entry?.[dataKey as keyof IBarDataItem]}`) < 0
                    ? negativeColor
                    : color;

                return <Cell key={key} fill={cellFill} />;
              })}
              <Tooltip formatter={barTooltipFormatter} />
              <Legend wrapperStyle={{ bottom: '-10px' }} />
            </Bar>
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
});

export default BarChartComponent;
