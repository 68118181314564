import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Plugin,
  Getter,
  Template,
  TemplateConnector
} from '@devexpress/dx-react-core';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { numberWithCommas } from '../TableContainer/TableUtilities/columnFormatter';
import {
  renderDefaultCell,
  renderCurrencyCell,
  renderNumberCell,
  renderPercentCell,
  renderProfit,
  renderTimeCell,
  renderMinutesCell,
  renderFloatCell
} from '../TableContainer/TableUtilities/defaultCells';

class GrandTotals extends Component {
  renderTemplateRow = () => (
    <Template
      name="tableRow"
      predicate={({ tableRow: { type } }) => type === 'totals'}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      {(params) => <VirtualTable.Row {...params} />}
    </Template>
  );

  renderTemplateCell = () => (
    <Template
      name="tableCell"
      predicate={({ tableRow, tableColumn }) =>
        tableRow.type === 'totals' &&
        tableColumn.type.toString() === Symbol('data').toString()
      }
    >
      {({
        tableColumn: {
          column: { name: columnName }
        }
      }) => (
        <TemplateConnector>
          {({ grandTotals }) => {
            const { totalsMapping, breakdownButton, hoverTotals } = this.props;
            const type = totalsMapping[columnName]
              ? totalsMapping[columnName].type
              : 'default';
            switch (type) {
              case 'header':
                return renderDefaultCell(<b>Grand Totals:</b>);
              case 'string':
                return renderDefaultCell(grandTotals[columnName] || '');
              case 'number':
                return renderNumberCell(grandTotals[columnName] || 0);
              case 'float':
                return renderFloatCell(grandTotals[columnName] || 0);
              case 'clickTotals_hover':
                return hoverTotals.length > 0
                  ? renderDefaultCell(
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        {numberWithCommas(grandTotals[columnName] || 0)}
                        <div
                          style={{
                            display: 'flex',
                            marginLeft: '8px'
                          }}
                        >
                          <Tooltip
                            title={
                              <div
                                style={{
                                  textTransform: 'capitalize'
                                }}
                              >
                                <div>
                                  {hoverTotals[0].device_id} - Test Clicks:{' '}
                                  {hoverTotals[0].test_clicks} Winning Clicks:{' '}
                                  {hoverTotals[0].winning_clicks}
                                </div>
                                {hoverTotals.length > 1 && (
                                  <div>
                                    {hoverTotals[1].device_id} - Test Clicks:{' '}
                                    {hoverTotals[1].test_clicks} Winning Clicks:{' '}
                                    {hoverTotals[1].winning_clicks}
                                  </div>
                                )}
                              </div>
                            }
                            placement="bottom"
                          >
                            <InfoOutlined />
                          </Tooltip>
                        </div>
                      </div>
                    )
                  : renderNumberCell(grandTotals[columnName] || 0);
              case 'average_currency':
              case 'currency':
                return renderCurrencyCell(grandTotals[columnName] || 0);
              case 'time':
                return renderTimeCell(grandTotals[columnName] || 0);
              case 'average_minutes':
              case 'minutes':
                return renderMinutesCell(grandTotals[columnName] || 0);
              case 'profit':
                return renderProfit(grandTotals[columnName] || 0, '$');
              case 'percent':
                return renderPercentCell(
                  grandTotals[columnName] || 0,
                  totalsMapping[columnName].profitColor
                );
              case 'breakdown':
                return renderDefaultCell(breakdownButton(grandTotals));
              case 'n/a':
                return renderDefaultCell('N/A');
              case 'drawerContainer':
                return renderDefaultCell(
                  grandTotals[columnName],
                  +grandTotals[columnName] > 0
                    ? totalsMapping[columnName].render
                    : null
                );
              default:
                return renderDefaultCell(' ');
            }
          }}
        </TemplateConnector>
      )}
    </Template>
  );

  renderTotalsRows = ({ tableBodyRows }) =>
    [].concat(
      [
        {
          key: 'totals',
          type: 'totals'
        }
      ],
      tableBodyRows
    );

  render() {
    return (
      <Plugin>
        <Getter name="tableBodyRows" computed={this.renderTotalsRows} />
        {this.renderTemplateRow()}
        {this.renderTemplateCell()}
      </Plugin>
    );
  }
}

GrandTotals.propTypes = {
  totalsMapping: PropTypes.instanceOf(Object)
};

GrandTotals.defaultProps = {
  totalsMapping: {}
};

export default GrandTotals;
