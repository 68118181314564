/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-cycle */
import { logError } from '../../Utilities/logError';
import { NetworkRequest } from '../Utilities/NetworkRequests/NetworkRequests';
import { getNanDataSorted } from './utilities';

export const expectArrayData = (data) =>
  data && typeof data === 'object' ? data : [];
const handleError = () => (error) => logError(error);
const splitAndUpperCase = (asset_type) =>
  ucWordsAssetType(asset_type.replace(/_/g, ' '));
const sortObjectAlphabetically = (key) => {
  return function (objectA, objectB) {
    if (objectA[key] > objectB[key]) {
      return 1;
    }
    if (objectA[key] < objectB[key]) {
      return -1;
    }
    return 0;
  };
};

const ucWordsAssetType = (assetTypeSplitted) => {
  const lowerCase = assetTypeSplitted.toLowerCase();
  return lowerCase.replace(
    /(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g,
    function ($1) {
      return $1.toUpperCase();
    }
  );
};

export const affiliateID = async (withoutAll = false, status = null) => {
  const { data } =
    (await NetworkRequest('affiliates', {
      action: 'getAffiliateDropdown',
      status
    }).catch(handleError)) ?? {};
  const agents = expectArrayData(data).map((affiliateData) => ({
    value: affiliateData.affiliate_id,
    display: `${affiliateData.affiliate_name} (#${affiliateData.affiliate_id})`,
    isGiraffe: affiliateData.is_giraffe
  }));
  if (!withoutAll) {
    agents.unshift({ value: 'all', display: 'All Affiliates', isGiraffe: '1' });
  }
  return agents;
};

export const affiliatesWithPixelID = async (id = 'all') => {
  const { data } = await NetworkRequest('pixels', {
    action: 'getAffiliatePixelsDropdown',
    vertical_id: id
  }).catch(handleError);
  const agents = expectArrayData(data).map((affiliateData) => ({
    value: affiliateData.affiliate_id,
    display: `${affiliateData.affiliate_name} (#${affiliateData.affiliate_id})`
  }));
  agents.unshift({ value: 'all', display: 'All Affiliates' });
  return agents;
};

export const tyType = async () => {
  const { data } = await NetworkRequest('reports', {
    action: 'getTyTypes'
  }).catch(handleError);
  const tyTypes = expectArrayData(data).map((tyData) => ({
    value: tyData,
    display: tyData.capitalize()
  }));
  tyTypes.unshift({ value: 'all', display: 'All TY Types' });
  return tyTypes;
};

export const everQuoteSources = async () => {
  const { data } = await NetworkRequest('affiliates', {
    action: 'getEverQuoteSourcesList'
  }).catch(handleError);
  const everQuoteSources = expectArrayData(data).map((source) => ({
    value: source.channel,
    display: source.channel.capitalize()
  }));
  everQuoteSources.unshift({ value: 'null', display: 'Select a Source' });
  return everQuoteSources;
};

export const lbType = async () => {
  const { data } = await NetworkRequest('reports', {
    action: 'getLbTypes'
  }).catch(handleError);
  const lbTypes = expectArrayData(data).map((lbData) => ({
    value: lbData,
    display: lbData.capitalize()
  }));
  lbTypes.unshift({ value: 'all', display: 'All LB Types' });
  return lbTypes;
};

export const banditSites = async () => {
  const { data } = await NetworkRequest('bandits', {
    action: 'getAssetsSites'
  }).catch(handleError);
  const sites = expectArrayData(data).map((siteData) => ({
    value: siteData.site_id,
    display: `${siteData.site_name}`
  }));
  sites.unshift({ value: 'all', display: 'Select a Vertical' });
  return sites;
};

export const siteOptions = async (
  selectAll = false,
  uuid = false,
  valueFormatter = false,
  filter = [],
  customOption = {}
) => {
  const { data } = await NetworkRequest('buyers', {
    action: 'getBuyerSites'
  }).catch(handleError);
  const formatUUIDValue = (siteData) => {
    return uuid ? siteData.site_uuid : siteData.id;
  };
  const sites = expectArrayData(data)
    .map((siteData) => ({
      value: valueFormatter
        ? valueFormatter(siteData)
        : formatUUIDValue(siteData),
      display: `${siteData.site_type.capitalize()}`
    }))
    .sort(sortObjectAlphabetically('display'));

  if (Object.keys(customOption).length) {
    sites.unshift({
      value: customOption.value,
      display: customOption.display,
      disabled: customOption.disabled
    });
  } else {
    sites.unshift({
      value: 0,
      display: selectAll ? 'All Verticals' : 'Select a Vertical'
    });
  }

  if (filter.length > 0) {
    return sites.filter((item) => !filter.includes(item.value));
  }
  return sites;
};

export const getPlatforms = async (device) => {
  const { data } = await NetworkRequest('reports', {
    action: 'getPlatformDropdown',
    device: +device !== 0 && +device !== 4 ? device : false
  }).catch(handleError);
  const sites = expectArrayData(data).map((platform) => ({
    value: platform.id,
    display: `${platform.display_name}`
  }));
  sites.unshift({ value: 0, display: 'All Platforms' });
  return sites;
};

export const getDevices = async (selectAll = false) => {
  const { data } = await NetworkRequest('reports', {
    action: 'getDeviceDropdown'
  }).catch(handleError);
  const sites = expectArrayData(data).map((device) => ({
    value: device.id,
    display: `${device.display_name.capitalize()}`
  }));
  sites.unshift({
    value: 0,
    display: selectAll ? 'All Devices' : 'Select a Device'
  });
  return sites;
};

export const assetNames = async () => {
  const { data } = await NetworkRequest('bandits', {
    action: 'getAssetNames'
  }).catch(handleError);
  const assetTypes = expectArrayData(data).map((typeData) => ({
    value: typeData.id,
    display: `${typeData.name}`
  }));
  assetTypes.unshift({ value: 'all', display: 'Select a Type' });
  return assetTypes;
};

export const getTimeZones = async (location, site_id, asset_type) => {
  const { data } = await NetworkRequest('users', {
    action: 'getSupportedTimezones',
    location,
    site_id,
    asset_type
  }).catch(handleError);
  const timezones = expectArrayData(data).map((timezone) => ({
    value: timezone.timezone_short,
    display: `${timezone.timezone_name} (${timezone.timezone_value})`
  }));

  timezones.unshift({ value: 0, display: 'Select a Timezone' });
  return timezones;
};

export const getCreatives = async (
  location,
  site_id,
  asset_type,
  forDomain = false
) => {
  const { data } = await NetworkRequest('creatives', {
    action: 'getCreatives',
    location,
    site_id,
    asset_type
  }).catch(handleError);
  return expectArrayData(data).map((assetData) => ({
    value: assetData.id,
    display: forDomain ? `${assetData.name}` : `${assetData.asset}`,
    image: `${assetData.image}`
  }));
};

export const getPresellsBySite = async (site_id) => {
  const { data } = await NetworkRequest('bandits', {
    action: 'getPresellsBySite',
    site_id
  }).catch(handleError);
  const presells = expectArrayData(data).map((presell) => ({
    value: presell.presell_name,
    display: `${presell.presell_name}`
  }));
  presells.unshift({ value: 0, display: 'Select a Presell Page' });
  return getNanDataSorted(presells, 'value');
};

export const assetTests = async () => {
  const { data } = await NetworkRequest('bandits', {
    action: 'getAssetsTests'
  }).catch(handleError);
  const assetTests = expectArrayData(data).map((testDate) => ({
    value: testDate.id,
    display: `${testDate.test_name}`
  }));
  assetTests.unshift({ value: 'all', display: 'Select a Test' });
  return assetTests;
};

export const assetTypes = async () => {
  const { data } = await NetworkRequest('bandits', {
    action: 'getAssetsTypes'
  }).catch(handleError);
  const assetTypes = expectArrayData(data).map((typeData) => ({
    value: typeData.asset_type,
    display: `${splitAndUpperCase(typeData.asset_type)}`
  }));
  assetTypes.unshift({ value: 'all', display: 'Select a Type' });
  return assetTypes;
};

export const agentFilter = async () => {
  const { data } = await NetworkRequest('agents', {
    action: 'getActiveAgents'
  }).catch(handleError);
  const agents = expectArrayData(data).map((agentData) => ({
    value: agentData.user_id,
    display: `${agentData.name} (${agentData.email})`
  }));
  agents.unshift({ value: 'none', display: 'Select an Agent' });
  return agents;
};

export const buyerList = async (
  site_id = 0,
  includeRetreaver = false,
  buyerType = false,
  includePhone = false,
  all = false,
  customName = false,
  allStatus = false,
  include_cpl = true
) => {
  const { data } = await NetworkRequest('buyers', {
    action: 'getBuyersBySiteId',
    site_id,
    includeRetreaver,
    buyer_type: buyerType,
    all_status: allStatus,
    include_cpl
  }).catch(handleError);
  const buyers = expectArrayData(data).map((buyerData) => ({
    value: buyerData.buyer,
    display: `${buyerData.buyer} (${buyerData.buyer_type})`
  }));
  if (includePhone) {
    buyers.unshift({ value: 'NA', display: 'NA Phone' });
  }
  if (customName) {
    buyers.unshift({ value: 'custom', display: 'Use Custom Name' });
  }
  if (all) {
    buyers.unshift({ value: 'all', display: 'All Buyers' });
  } else {
    buyers.unshift({ value: '-1', display: 'Select A Buyer' });
  }
  return buyers;
};

export const buyerLegsList = async (
  site_id = 0,
  includeRetreaver = false,
  buyerType = false
) => {
  const { data } = await NetworkRequest('buyers', {
    action: 'getBuyersByType',
    site_id,
    includeRetreaver,
    buyer_type: buyerType
  }).catch(handleError);
  const buyers = data.map((buyerData) => ({
    value: buyerData.buyer,
    display: `${buyerData.buyer}`
  }));
  buyers.unshift({ value: 'all', display: 'All Buyers' });
  return buyers;
};

export const buyerIdsList = async (
  allText = false,
  withoutAll = false,
  excludeLegs = false
) => {
  const params = {
    action: 'getBuyersBySiteId',
    site_id: '0'
  };

  if (excludeLegs) {
    params.excludeExclusiveLeg = true;
  }

  const { data } = await NetworkRequest('buyers', params).catch(handleError);

  const buyers = expectArrayData(data).map((buyerData) => ({
    value: buyerData.id,
    display: `${buyerData.buyer} (${buyerData.buyer_type})`
  }));

  if (!withoutAll) {
    buyers.unshift({ value: allText ? 'all' : '0', display: 'All Buyers' });
  }

  return buyers;
};

export const buyersByTypeList = async (type = 2) => {
  const { data } = await NetworkRequest('buyers', {
    action: 'getBuyersByType',
    buyer_type: type
  }).catch(handleError);
  const buyers = expectArrayData(data).map((buyerData) => ({
    value: buyerData.buyer,
    display: buyerData.buyer
  }));
  buyers.unshift({ value: 'all', display: 'All Buyers' });
  return buyers;
};

export const getSellers = async (site_id, allOption = true) => {
  const { data } = await NetworkRequest('sellers', {
    action: 'getAll',
    site: site_id
  }).catch(handleError);
  const sellers = expectArrayData(data).map((sellerData) => ({
    value: sellerData.id,
    display: sellerData.name,
    lookbackDaysDefault: sellerData.lookback_days
  }));
  if (allOption) {
    sellers.unshift({ value: 'all', display: 'All Sellers' });
  } else {
    sellers.unshift({
      value: '-1',
      display: 'Select a Seller',
      disabled: true
    });
  }
  return sellers;
};

export const buyerListWithinDateRange = async (
  startDate,
  endDate,
  dateRange,
  site_id = 0,
  all = false,
  onlyActiveBuyers = true
) => {
  const { data } = await NetworkRequest('buyers', {
    action: 'getBuyersBySiteIdWithinDateRange',
    site_id,
    startDate,
    endDate,
    dateRange,
    onlyActiveBuyers
  }).catch(handleError);
  const buyers = expectArrayData(data).map((buyerData) => ({
    value: buyerData.buyer,
    display: `${buyerData.buyer} (${buyerData.buyer_type})`
  }));
  if (all) {
    buyers.unshift({ value: 'all', display: 'All Buyers' });
  } else {
    buyers.unshift({ value: '-1', display: 'All Buyers' });
  }
  return buyers;
};

export const statesList = async (
  alt_first_opt = false,
  multiCheckbox = false,
  defaultOption = false
) => {
  const { data } = await NetworkRequest('agents', {
    action: 'getStates'
  }).catch(handleError);
  let states = [];
  if (multiCheckbox) {
    states = expectArrayData(data).map((statesData) => ({
      optionVal: statesData.state_code,
      label: `${statesData.state}`
    }));
    states.unshift({ optionVal: 'all', label: 'All' });
  } else if (defaultOption) {
    states = expectArrayData(data).map((statesData) => ({
      value: statesData.state_code,
      display: `${statesData.state}`
    }));
    states.unshift({
      value: 'default',
      display: 'Default'
    });
  } else {
    states = expectArrayData(data).map((statesData) => ({
      value: statesData.state_code,
      display: `${statesData.state}`
    }));
    alt_first_opt
      ? states.unshift({ value: 'all', display: 'All' })
      : states.unshift({
          value: '0',
          display: 'Select a State'
        });
  }
  return states;
};

export const formOptions = async (
  siteID,
  isGiraffe = '0',
  useNameAsValue = false,
  excludeOttoBot = true
) => {
  if (!siteID) {
    return [];
  }
  const { data } = await NetworkRequest('reports', {
    action: 'getFormDropDown',
    site_id: siteID,
    exclude_ottobot: excludeOttoBot
  }).catch(handleError);
  let options = expectArrayData(data);
  if (isGiraffe === '0' || isGiraffe === false) {
    options = options.filter((i) => i.giraffe !== '1');
  }
  options = options.map((statesData) => ({
    value: useNameAsValue ? statesData.display_name : statesData.form_id,
    display: `${statesData.display_name}`
  }));
  options.unshift({ value: '0', display: 'Select a Form', disabled: true });
  return options;
};

export const formConversionOptions = async (siteID, excludeOttoBot = true) => {
  if (!siteID) {
    return [];
  }
  const { data } = await NetworkRequest('reports', {
    action: 'getFormDropDown',
    site_id: siteID,
    exclude_ottobot: excludeOttoBot
  }).catch(handleError);
  const options = expectArrayData(data).map((statesData) => ({
    value: statesData.form_id,
    display: `${statesData.display_name}`
  }));
  options.unshift({ value: 'all', display: 'All Forms' });
  return options;
};

export const getUsers = async (isExternal = false) => {
  const { data } = await NetworkRequest('users', {
    action: 'getUsers',
    is_external: isExternal ? true : null
  }).catch(handleError);
  const options = expectArrayData(data).map((usersData) => ({
    value: usersData.email,
    display: `${usersData.email}`
  }));
  if (isExternal) {
    options.unshift({ value: '0', display: 'Select a user' });
  }
  return options;
};

export const getAPIUsers = async () => {
  const { data } = await NetworkRequest('users', {
    action: 'getApiUsers'
  }).catch(handleError);
  const options = expectArrayData(data).map((usersData) => ({
    value: usersData.api_key,
    display: `#${usersData.affiliate_id} - ${usersData.api_key}`
  }));
  options.unshift({ value: 'all', display: 'All API Users' });
  return options;
};

export const getExternalUsers = async () => {
  const { data } = await NetworkRequest('affiliates', {
    action: 'getUsers',
    is_external: 1
  }).catch(handleError);
  const options = expectArrayData(data).map((usersData) => ({
    value: usersData.id,
    display: `${usersData.email}`
  }));
  options.unshift({ value: '0', display: 'Select a user' });
  return options;
};

export const getValidKeys = async (buyer_name) => {
  const { data } = await NetworkRequest('constraints', {
    action: 'getValidKeys',
    buyer_name
  });
  const options = expectArrayData(data).map((row) => ({
    value: `${row.key}!_!${row.type}`,
    display: `${row.key}`
  }));
  options.unshift({ value: '0', display: 'Select a key' });
  return options;
};

export const getUserGroups = async () => {
  const { data } = await NetworkRequest('users', {
    action: 'getUserGroups'
  }).catch(handleError);
  const options = expectArrayData(data).map((data) => ({
    value: data.id,
    display: data.group_name
  }));
  options.unshift({ value: '0', display: 'Select a group' });
  return options;
};

export const getTrafficSources = async (notAll = false) => {
  const { data } = await NetworkRequest('affiliates', {
    action: 'getTrafficSources'
  }).catch(handleError);
  const options = expectArrayData(data).map((data) => ({
    value: data.id,
    display: data.traffic_source_name
  }));
  if (!notAll) {
    options.unshift({ value: '0', display: 'All Traffic Sources' });
  } else {
    options.unshift({ value: '-1', display: 'Select Traffic Source' });
  }
  return options;
};

export const getTYOffers = async (siteID) => {
  const { data } = await NetworkRequest('ty', {
    action: 'getTYOffers',
    site_id: siteID
  }).catch(handleError);
  const options = expectArrayData(data).map((data) => ({
    value: data.id,
    display: data.headline
  }));
  return options;
};
