/* eslint-disable default-param-last */
import moment from 'moment';
import { HANDLE_CHANGE } from '../actions/actions';
import { checkAdmin } from '../../PermissionsWrappers/permissionChecks';

export const initialState = {
  site: '2',
  lookbackDayCriteriaState: 'custom',
  lookbackDaysState: '',
  siteUUID: '4c7879a2-da7b-4fe9-af69-8926cfc40072',
  automaticTestingTableSiteID: '2',
  affiliateID: !checkAdmin() ? '' : 'All Affiliates',
  agentFilter: 'none',
  affiliateSite: 'none',
  buyer_step: '-1',
  pixelType: '-1',
  pixelStep: '-1',
  buyer: '-1',
  seller: '-1',
  sellerState: '-1',
  buyerID: 'all',
  carriers: 'all',
  pixelLocation: '-1',
  assetTypes: 'all',
  assetTests: 'all',
  assetNames: 'all',
  agentListStatus: '1',
  buyerType: 'all',
  affiliateType: 'all',
  apiType: 'all',
  apiUser: 'all',
  missedBidGrouping: ['all'],
  chargeType: 'all',
  leadResult: 'all',
  rotationAssetType: 'header',
  rotationLocation: 'presell',
  state: 'all',
  includedStates: ['all'],
  insuredStatus: 'all',
  buyers: 'all',
  salesType: 'all',
  lead_id: '',
  pingID: '',
  formID: '8',
  legacy: 0,
  device: 'all',
  top_amount: '5',
  alvPlus: false,
  userGroup: '0',
  banditTestGroup: '0',
  subID: 1,
  platform: 0,
  platform_device: 0,
  reloadReport: false,
  tyPage: 0,
  tyPageName: '',
  tyType: 'all',
  isGiraffeAffiliate: localStorage.getItem('isGiraffeAffiliate') || '0',
  insurance: '1',
  homeOwnership: '1',
  presets: '0',
  multipleVehicle: '0',
  ageGroup: ['all'],
  creditRating: ['all'],
  statesList: ['all'],
  multipleDriver: ['all'],
  multiVehicle: ['all'],
  homeOwner: ['all'],
  insured: ['all'],
  partial: 'all',
  source: 'all',
  year: moment().year().toString(),
  excludeBuyerType: 0,
  hiddenFilterList: [],
  endMonth: '1',
  startMonth: '1',
  time: 'all',
  state_id: '',
  sellerLookbackDefault: {}
};

export const filters = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_CHANGE:
      return {
        ...state,
        [action.state]: action.value
      };
    default:
      return state;
  }
};
