/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import { Grid, Tooltip, CircularProgress } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '@mui/material/Button';
import { NetworkRequest } from '../../../Utilities/NetworkRequests/NetworkRequests';
import { colors } from '../../../../Utilities/LenoxColors';
import Dialog from '../../../Dialog';
import { getColumnWidths } from '../../../TableContainer/TableUtilities/getColumnWidths';
import { getColumnCompare } from '../../../TableContainer/TableUtilities/columnFormatter';
import Table from '../../../TableContainer/Table';
import { renderDefaultCell } from '../../../TableContainer/TableUtilities/defaultCells';
import { handleChange as updateFilters } from '../../../Filters/actions/actions';
import { getFromLocalStorage } from '../../../Utilities/saveStateHelper';

const styles = () => ({
  tableContainer: {
    border: 'solid 1px rgba(20,20,20,.3)',
    borderRadius: '8px',
    margin: '1rem'
  },
  tableTitle: {
    fontSize: '1.25rem',
    textAlign: 'center',
    padding: '.5rem',
    backgroundColor: colors.lenoxDark2,
    color: colors.lenoxLight1,
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    marginBottom: '1rem'
  },
  statusButtons: {
    textAlign: 'center',
    verticalAlign: 'middle'
  },
  resetButton: {
    backgroundColor: colors.lightGray,
    color: colors.lenoxLight1,
    marginBottom: '2rem'
  }
});

const columns = [
  { name: 'display_name', title: 'Form Name', compare: 'priority' },
  { name: 'device', title: 'Device', compare: 'priority', width: 120 },
  { name: 'short_form', title: 'Form Type', compare: 'priority', width: 120 },
  {
    name: 'spanish_form',
    title: 'Spanish Form',
    compare: 'priority',
    width: 120
  },
  { name: 'status', title: 'Testing Status', compare: 'priority' }
];

class ManualAutomaticTesting extends Component {
  state = {
    loading: false,
    rows: [],
    openConfirmDialog: false,
    dialog: {
      message: '',
      type: 'error',
      title: 'Error'
    },
    currentRow: false,
    confirmationResetAllDialog: {
      message: '',
      type: 'confirm',
      title: 'Error'
    }
  };

  componentDidMount() {
    // if (this.props.filters !== undefined) {
    //   this.props.updateFilters('automaticTestingTableSiteID', this.props.filters.site_id);
    // }
    if (this.props.filters !== undefined) {
      this.props.updateFilters(
        'automaticTestingTableSiteID',
        getFromLocalStorage(
          'automaticTestingFormSiteID',
          this.props.filters.site_id
        )
      );
    }
    this.props.updateFilters(
      'automaticTestingTableSiteID',
      getFromLocalStorage('automaticTestingFormSiteID', this.props.site)
    );
    this.loadData();
  }

  loadData = async () => {
    this.setState({ loading: true });
    const { data } = await NetworkRequest(
      'affiliates',
      {
        affiliate_id: this.props.row
          ? this.props.row.affiliate_id
          : this.props.filters.affiliate_id,
        site_id:
          this.props.selectedPage === 'affiliate_form_conversion_report'
            ? this.props.filters.site_id
            : this.props.automaticTestingTableSiteID
      },
      'getFormList'
    );
    if (data !== undefined) {
      this.setState({ rows: data, loading: false });
    }
  };

  componentDidUpdate(prevProps) {
    const { automaticTestingTableSiteID } = this.props;
    if (prevProps.automaticTestingTableSiteID !== automaticTestingTableSiteID)
      this.loadData();
    if (this.props.filters !== undefined) {
      this.props.updateFilters(
        'automaticTestingTableSiteID',
        getFromLocalStorage(
          'automaticTestingFormSiteID',
          this.props.filters.site_id
        )
      );
    }
    this.props.updateFilters(
      'automaticTestingTableSiteID',
      getFromLocalStorage('automaticTestingFormSiteID', this.props.site)
    );
  }

  toggleResetAllConfirm = (
    message = '',
    type = 'confirm',
    title = 'Confirm Reset'
  ) =>
    this.setState({
      confirmationResetAllDialog: {
        ...this.state.confirmationResetAllDialog,
        message,
        type,
        title
      }
    });

  handleReset = () => {
    this.toggleResetAllConfirm(
      'Do you want to reset stats for automatic testing? All forms will be reset to be tested like they are brand new.'
    );
  };

  handleSubmitRequest = async (newStatus, row, oldStatus) => {
    const {
      status,
      affiliate_id,
      form_id,
      variant_id,
      vertical_id,
      display_name
    } = row;
    this.updateRow(row, 'processing', true);
    this.updateRow(row, 'status', newStatus, oldStatus);
    const { data } = await NetworkRequest(
      'affiliates',
      {
        status,
        start_date: moment().format('YYYY-MM-DD'),
        affiliate_id,
        form_id,
        variant_id,
        vertical_id,
        display_name
      },
      'setStatus'
    );
    this.updateRow(row, 'processing', false);
    this.handleResponse(data);
  };

  handleResetRequest = () => {
    this.setState({ submitting: true }, () =>
      NetworkRequest(
        'affiliates',
        {
          affiliate_id: this.props.row
            ? this.props.row.affiliate_id
            : this.props.filters.affiliate_id
        },
        'resetAutomaticForm'
      )
        .then(({ data }) => {
          this.handleResetResponse(data);
        })
        .catch(() => this.setState({ submitting: false }))
    );
  };

  handleResponse = (data) => {
    this.setState({ submitting: false }, () => {
      this.toggleDialog(
        data.message,
        data.success ? 'success' : 'error',
        data.success ? 'Success' : 'Error'
      );

      if (data.success) {
        this.loadData();
      }
    });
  };

  toggleDialog = (message = '', type = 'error', title = 'Error') =>
    this.setState({
      dialog: { ...this.state.dialog, message, type, title }
    });

  renderDialog = () => (
    <Dialog
      title={this.state.dialog.title}
      children={this.state.dialog.message}
      open={!!this.state.dialog.message}
      type={this.state.dialog.type}
      onRequestClose={() => setTimeout(() => this.toggleDialog(), 300)}
      saveButtonText="Submit"
    />
  );

  handleResetResponse = (data) => {
    this.setState({ submitting: false }, () => {
      this.toggleResetAllConfirm(
        data.message,
        data.success ? 'success' : 'error',
        data.success ? 'Success' : 'Error'
      );
    });
  };

  changeStatus = async (newStatus, row) => {
    this.updateRow(row, 'processing', true);
    const { data } = await NetworkRequest(
      'affiliates',
      {
        affiliate_id: row.affiliate_id,
        form_id: row.form_id,
        device: row.device,
        vertical_id: row.vertical_id,
        automatic_testing_id: row.automatic_testing_id,
        status: newStatus
      },
      'blockAutomaticTestingForm'
    );
    this.updateRow(row, 'processing', false);
    if (data && data.success) {
      this.updateRow(row, 'status', newStatus);
    } else {
      this.toggleDialog(
        data && !data.success && data.message
          ? data.message
          : 'Something went wrong. Please try again.'
      );
    }
  };

  openConfirmationDialog = (row) =>
    this.setState({ openConfirmDialog: true, currentRow: { ...row } });

  renderConfirmDialog = () => (
    <Dialog
      title="Confirm"
      children="Are you sure you want to override this removal? Stats will be reassessed after 7 days."
      open={this.state.openConfirmDialog}
      onRequestSave={() => {
        this.setState({ openConfirmDialog: false });
        this.changeStatus(1, this.state.currentRow || {});
      }}
      onRequestClose={() =>
        setTimeout(() => this.setState({ openConfirmDialog: false }), 300)
      }
      type="Confirm"
      saveButtonText="Submit"
    />
  );

  updateRow = (row, name, value, oldStatus) => {
    const { rows } = this.state;
    rows.forEach(({ form_id, device, variant_id }, index) => {
      if (
        row.form_id === form_id &&
        row.device === device &&
        row.variant_id === variant_id
      ) {
        if (oldStatus === 3) {
          rows[index].not_tested = false;
        }
        rows[index][name] = value;
      }
    });
    this.setState({ rows });
  };

  renderResetAllDialog = () => (
    <Dialog
      title={this.state.confirmationResetAllDialog.title}
      children={this.state.confirmationResetAllDialog.message}
      open={!!this.state.confirmationResetAllDialog.message}
      onRequestSave={async () => {
        await this.handleResetRequest();
        this.toggleResetAllConfirm('');
      }}
      onRequestClose={() => setTimeout(() => this.toggleResetAllConfirm(), 300)}
      type={this.state.confirmationResetAllDialog.type}
      saveButtonText="Yes"
      cancelButtonText="No"
    />
  );

  statusButton = () => ({
    0: {
      color: 'red',
      tooltip: 'Click to unblock',
      text: 'Blocked',
      newStatus: 1
    },
    1: {
      color: 'green',
      tooltip: 'Click to block',
      text: 'Testing',
      newStatus: 0
    },
    2: {
      color: 'red',
      tooltip: 'Click to override',
      text: 'Auto Removed',
      newStatus: 1
    },
    3: {
      color: 'blue',
      tooltip: 'Click to begin testing',
      text: 'Not Tested',
      newStatus: 1
    }
  });

  cellComponent = ({ row, column }) => {
    switch (column.name) {
      case 'status':
        const statusData = this.statusButton()[+row.status];
        return renderDefaultCell(
          <Tooltip title={statusData.tooltip} placement="top">
            <Button
              variant="contained"
              style={{
                color: 'white',
                background: row.processing ? colors.lightGray : statusData.color
              }}
              onClick={() =>
                +row.status === 2
                  ? this.openConfirmationDialog(row)
                  : +row.status === 3
                  ? this.handleSubmitRequest(
                      statusData.newStatus,
                      row,
                      row.status
                    )
                  : this.changeStatus(statusData.newStatus, row)
              }
              disabled={row.processing}
            >
              {row.processing ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                statusData.text
              )}
            </Button>
          </Tooltip>
        );
      case 'spanish_form':
        return renderDefaultCell(row.is_spanish ? 'Yes' : 'No');
      case 'short_form':
        return renderDefaultCell(row[column.name] === 1 ? 'Partial' : 'Full');
      default:
        return renderDefaultCell(row[column.name]);
    }
  };

  render() {
    return (
      <>
        <Grid item className={this.props.classes.tableContainer}>
          <div className={this.props.classes.tableTitle}>
            Automatic Testing Form List
          </div>
          <Table
            loading={this.state.loading}
            rows={this.state.rows}
            columns={columns}
            cellComponent={this.cellComponent}
            drawerTitle="Automatic Testing Form List"
            defaultColumnWidths={getColumnWidths(columns)}
            getColumnCompare={getColumnCompare(columns)}
            tableColumnLocalStorageName="automatic_testing_form_list"
          />
          <Grid item xs={12} align="center">
            <Button
              variant="contained"
              className={this.props.classes.resetButton}
              onClick={() => this.handleReset()}
            >
              Reset Stats
            </Button>
          </Grid>
        </Grid>
        {this.renderConfirmDialog()}
        {this.renderResetAllDialog()}
        {this.renderDialog()}
      </>
    );
  }
}

export default connect(
  (state) => ({
    site: state.filters.site,
    automaticTestingTableSiteID: state.filters.automaticTestingTableSiteID,
    selectedPage: state.pageReducer.selectedPage
  }),
  (dispatch) =>
    bindActionCreators(
      {
        updateFilters
      },
      dispatch
    )
)(withStyles(ManualAutomaticTesting, styles));
