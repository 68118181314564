import {
  affiliateID,
  siteOptions,
  buyerListWithinDateRange,
  getTrafficSources
} from '../../../common/commonRequests';
import { EXCLUDE_BUYER_TYPE_OPTIONS } from '../../../common/dropdownConstants';

export const filters = () => ({
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions(),
    value: '2',
    inputWidth: 3
  },
  affiliateID: {
    name: 'affiliateID',
    type: 'autocomplete',
    label: 'Affiliate',
    options: () => affiliateID(),
    value: 'all',
    inputWidth: 3
  },
  trafficSource: {
    name: 'trafficSource',
    type: 'select',
    label: 'Traffic Source',
    defaultVal: '0',
    value: '0',
    inputWidth: 3,
    options: () => getTrafficSources()
  },
  buyer: {
    name: 'buyer',
    type: 'select',
    label: 'Buyer',
    options: (...args) => buyerListWithinDateRange(...args, true, false),
    value: '-1',
    inputWidth: 3,
    dependencyName: 'site',
    withinDateRange: true
  },
  excludeBuyerType: {
    name: 'excludeBuyerType',
    label: 'Type',
    type: 'select',
    options: () => EXCLUDE_BUYER_TYPE_OPTIONS,
    value: 0
  }
});
