/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper, CircularProgress as Progress } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import {
  SortingState,
  SelectionState,
  FilteringState,
  PagingState,
  GroupingState,
  IntegratedGrouping,
  CustomPaging,
  IntegratedSorting,
  RowDetailState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableFilterRow,
  TableGroupRow,
  PagingPanel,
  Toolbar,
  GroupingPanel,
  DragDropProvider,
  TableColumnVisibility,
  ColumnChooser,
  TableColumnReordering
} from '@devexpress/dx-react-grid-material-ui';
import '../../../assets/react-datagrid.css';
import { uniq } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { customizeDataGrid } from './TableContainer';
import { isMobileDevice } from '../../common/utilities';
import { handleChange } from '../../lenox/actions';
import ActionDrawer from '../../PageContainer/ActionDrawer';

const styles = () => ({
  loadingShadingMui: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, .3)'
  },
  loadingIconMui: {
    position: 'absolute',
    fontSize: 20,
    top: '55%',
    left: '45%'
  }
});

class TableRemotePag extends Component {
  static propTypes = {
    allowedPageSizes: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    cellComponent: PropTypes.func.isRequired,
    defaultColumnWidths: PropTypes.array.isRequired,
    defaultSortingOrder: PropTypes.array.isRequired,
    getColumnCompare: PropTypes.array.isRequired,
    tableColumnLocalStorageName: PropTypes.string.isRequired,
    changeCurrentPage: PropTypes.func.isRequired,
    changePageSize: PropTypes.func.isRequired,
    changeFilters: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    height: PropTypes.number,
    mobileHeight: PropTypes.number
  };

  static defaultProps = {
    height: 650,
    mobileHeight: 500,
    columnChooser: true,
    grouping: true,
    filters: true
  };

  getColumnOrder = () => {
    const { columns, dataGridCustomization, tableColumnLocalStorageName } =
      this.props;
    const storedOrder =
      dataGridCustomization.columnOrder[tableColumnLocalStorageName];
    if (storedOrder && typeof storedOrder === 'object') {
      if (storedOrder.length === (columns || []).length) {
        return storedOrder;
      }
      const addNewColumns = (columns || [])
        .filter(({ name }) => storedOrder.indexOf(name) === -1)
        .map((column) => column.name);
      const removedMissingColumns = storedOrder.filter(
        (columnName) =>
          (columns || []).map((column) => column.name).indexOf(columnName) > -1
      );
      const newDataGrid = [...removedMissingColumns, ...addNewColumns];
      this.updateDataGrid('columnOrder', newDataGrid);
      return newDataGrid;
    }
    return (columns || []).map((column) => column.name);
  };

  updateDataGrid = (type, update) => {
    const { dataGridCustomization, tableColumnLocalStorageName, handleChange } =
      this.props;
    const newDataGrid = {
      ...dataGridCustomization,
      [type]: {
        ...dataGridCustomization[type],
        [tableColumnLocalStorageName]: uniq(update)
      }
    };
    customizeDataGrid(newDataGrid);
    handleChange('dataGridCustomization', newDataGrid);
  };

  getDefaultHiddenColumns = () => {
    const { tableColumnLocalStorageName, dataGridCustomization } = this.props;
    if (!dataGridCustomization.hideColumns[tableColumnLocalStorageName]) {
      return [
        ...(dataGridCustomization.hideColumns[tableColumnLocalStorageName] ||
          [])
      ];
    }
    return dataGridCustomization.hideColumns[tableColumnLocalStorageName];
  };

  columnFilterWarning = () => {
    const filter = uniq(this.getDefaultHiddenColumns());
    if (filter.length > 0) {
      return (
        <div
          style={{
            color: 'red',
            fontSize: '12px',
            textAlign: 'right',
            marginTop: '1.5rem'
          }}
        >
          Your column filter is enabled
        </div>
      );
    }
    return null;
  };

  renderTable = (classes) => (
    <Paper>
      <Grid rows={this.props.rows} columns={this.props.columns}>
        <FilteringState onFiltersChange={this.props.changeFilters} />
        <SortingState defaultSorting={this.props.defaultSortingOrder} />
        <IntegratedSorting columnExtensions={this.props.getColumnCompare} />
        <GroupingState
          defaultGrouping={this.props.defaultGrouping || false}
          defaultExpandedGroups={this.props.defaultExpandedGroups || false}
        />
        <RowDetailState defaultExpandedRowIds={[]} />
        <PagingState
          currentPage={this.props.currentPage}
          onCurrentPageChange={this.props.changeCurrentPage}
          pageSize={this.props.pageSize}
          onPageSizeChange={this.props.changePageSize}
        />
        <IntegratedGrouping />
        <CustomPaging totalCount={this.props.totalCount} />
        <SelectionState defaultSelection={[1, 3, 18]} />
        <DragDropProvider />
        <VirtualTable
          height={
            (!isMobileDevice() && this.props.height) || this.props.mobileHeight
          }
          columnExtensions={this.props.defaultColumnWidths}
          cellComponent={this.props.cellComponent}
        />
        <TableColumnReordering
          order={this.getColumnOrder()}
          onOrderChange={(update) => this.updateDataGrid('columnOrder', update)}
        />
        <TableHeaderRow showSortingControls allowDragging />
        <TableColumnVisibility
          onHiddenColumnNamesChange={(update) =>
            this.updateDataGrid('hideColumns', update)
          }
          defaultHiddenColumnNames={this.getDefaultHiddenColumns()}
        />
        {this.props.filters && <TableFilterRow />}
        <PagingPanel pageSizes={this.props.allowedPageSizes} />
        {this.props.grouping && <TableGroupRow />}
        {(this.props.columnChooser || this.props.grouping) && <Toolbar />}
        {this.props.columnChooser && <ColumnChooser />}
        {this.props.grouping && (
          <GroupingPanel
            showSortingControls
            allowDragging
            showGroupingControls
          />
        )}
        {typeof this.props.renderRowDetail === 'function' &&
          this.props.renderRowDetail()}
      </Grid>
      {this.props.loading && (
        <div className={classes.loadingShadingMui}>
          <Progress
            color="inherit"
            size={140}
            className={classes.loadingIconMui}
          />
        </div>
      )}
    </Paper>
  );

  renderCombinedTableFilter = () => {
    const { classes } = this.props;
    return (
      <div>
        {this.columnFilterWarning()}
        {this.renderTable(classes)}
      </div>
    );
  };

  render() {
    return (
      <>
        {isMobileDevice() && !this.props.mobileVisible ? (
          <div style={{ textAlign: 'center' }}>
            <ActionDrawer
              fullWidth={false}
              style={{ width: '50%' }}
              drawerMobTable
              buttonTitle="Show Report"
              drawerTitle={this.props.drawerTitle || 'Show Report'}
              content={this.renderCombinedTableFilter()}
              mobileButtonStyle
            />
          </div>
        ) : (
          this.renderCombinedTableFilter()
        )}
      </>
    );
  }
}

export default connect(
  (state) => ({
    dataGridCustomization: state.lenox.dataGridCustomization
  }),
  (dispatch) =>
    bindActionCreators(
      {
        handleChange
      },
      dispatch
    )
)(withStyles(TableRemotePag, styles));
